<template>
  <div></div>
</template>

<script>
export default {
  beforeMount() {
    window.location.replace('https://discord.gg/5Q58X7dNPT');
  },
};
</script>